import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min.js";
import "assets/css/style.css";
import "assets/js/flexible.js";

import ElementUI from "element-ui";
// import "element-ui/lib/theme-chalk/index.css";
import "./assets/theme/index.css";
Vue.use(ElementUI);
import Fragment from "vue-fragment";
Vue.use(Fragment.Plugin);
import getPageTitle from "@/utils/get-page-title";
import websocket from "./utils/websocket";
import * as echarts from "echarts";
import scroll from 'vue-seamless-scroll'
import { download } from '@/utils/request'
Vue.use(scroll)
Vue.prototype.$echarts = echarts;
Vue.prototype.$websocket = websocket
Vue.prototype.download = download
// 路由导航守卫
router.beforeEach((to, from, next) => {
  document.title = getPageTitle(to.name);
  if (to.path === "/login") {
    next();
  } else {
    const token = sessionStorage.getItem("userToken");
    if (!token) {
      next("/login");
    } else {
      localStorage.setItem("currentPathName",to.name)   //设置当前的路由名称
      store.commit('setPath')   //触发store的额数据更新
      next();
    }
  }
});

Vue.config.productionTip = false;
Vue.use(router);
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
