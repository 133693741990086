const user = {
  state: {
    headerStatus: true,
    //存放已经登录的用户信息
    userInfo: {},
  },
  mutations: {
    changeUserInfo(state, info) {
      state.userInfo = info;
    },
    changeState(state, data) {
      state.headerStatus = data;
    },    
  },
  actions: {
    saveUserInfo({ commit }, data) {
      commit("changeUserInfo", data);
    },
    saveLoginStates({ commit }, data) {
      commit("changeState", data);
    },
  },
  getters: (state) => state.userInfo,
};
export default user;
