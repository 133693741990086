import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

let router = new Router({
    // mode: 'history',
    routes: [
        {
            path: "/",
            name: "首页",
            component: () => import("views/index.vue"),
        },
        {
            path: "/set-gaugesManage",
            name: "系统配置-绑定数据点",
            component: () => import("views/set-gaugesManage.vue"),
        },
        {
            path: "/set-gaugesBind",
            name: "系统配置-计量表管理",
            component: () => import("views/set-gaugesBind.vue"),
        },
        {
            path: "/equip-ledger",
            name: "设备服务-设备台账服务",
            component: () => import("views/equip-ledger.vue"),
        },
        {
            path: "/equip-warn",
            name: "设备服务-告警监控服务",
            component: () => import("views/equip-warn.vue"),
        },
        {
            path: "/login",
            name: "登录",
            component: () => import("views/login.vue"),
        },
        {
            path: "/set-point",
            name: "系统配置-点位管理",
            component: () => import("views/set-point.vue"),
        },
        {
            path: "/set-sensor",
            name: "系统配置-传感器",
            component: () => import("views/set-sensor.vue"),
        },
        {
            path: "/set-jiliangbiao",
            name: "系统配置-计量表",
            component: () => import("views/set-jiliangbiao.vue"),
        },
        {
            path: "/set-outline",
            name: "系统配置-离线表",
            component: () => import("views/set-outline.vue"),
        },
        {
            path: "/set-outlineFile",
            name: "系统配置-离线表文件",
            component: () => import("views/set-outlineFile.vue"),
        },
        {
            path: "/set-role",
            name: "系统配置-角色管理",
            component: () => import("views/set-role.vue"),
        },
        {
            path: "/set-energyType",
            name: "系统配置-能源管理",
            component: () => import("views/set-energyType.vue"),
        },
        {
            path: "/set-equip",
            name: "系统配置-设备管理",
            component: () => import("views/set-equip.vue"),
        },
        {
            path: "/set-virtual",
            name: "系统配置-虚拟表",
            component: () => import("views/set-virtual.vue"),
        },
        {
            path: "/set-user",
            name: "系统配置-用户管理",
            component: () => import("views/set-user.vue"),
        },

        {
            path: "/manageIndex",
            name: "管理首页",
            component: () => import("views/manageIndex.vue"),
        },
        {
            path: "/energy-analysis",
            name: "能源分析-能耗分类报表",
            component: () => import("views/energy-analysis.vue"),
        },
        {
            path: "/equip-monitor",
            name: "设备列表-监控",
            component: () => import("views/equip-monitor.vue"),
        },
        {
            path: "/set-config",
            name: "设备列表-项目配置",
            component: () => import("views/set-config.vue"),
        },
        {
            path: "/QH_RJ",
            name: "QH_RJ项目首页",
            component: () => import("views/QH/QH_RJ.vue"),
        },
        {
            path: "/QH_KT",
            name: "QH_KT项目首页",
            component: () => import("views/QH/QH_KT.vue"),
        },
        {
            path: "/SG_RJ",
            name: "SG_RJ项目首页",
            component: () => import("views/SG/SG_RJ.vue"),
        },
        {
            path: "/SG_TYN",
            name: "SG_TYN项目首页",
            component: () => import("views/SG/SG_TYN.vue"),
        },
        {
            path: "/projectIndex",
            name: "项目首页",
            component: () => import("views/projectIndex.vue"),
        },
        {
            path: "/set-warn",
            name: "告警配置",
            component: () => import("views/set-warn.vue"),
        },
        {
            path: "/dataShow",
            name: "数据展示",
            component: () => import("views/Show/dataShow.vue"),
        }
    ],
});
export default router;
