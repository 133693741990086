// import { showInfoMsg, showErrorMsg } from '@/utils/popInfo'
import ElementUI from "element-ui";
function initWebSocket(e) {
  const wsUri = "ws://43.143.218.144:9084/alarmWebsocket";
  this.socket = new WebSocket(wsUri, [sessionStorage.getItem("userToken")]); //这里面的this都指向vue
  this.socket.onerror = webSocketOnError;
  this.socket.onopen = webSocketOnOpen;
  this.socket.onmessage = webSocketOnMessage;
  this.socket.onclose = closeWebsocket;
}
function webSocketOnError(e) {
  // ElementUI.Notification({
  //   title: "",
  //   message: "WebSocket连接发生错误" + e,
  //   type: "error",
  //   duration: 0,
  // });
}
function webSocketOnOpen() {
  console.log("连接成功!");
}
function webSocketOnMessage(e) {
  console.log(e);
  let webArr = [];
  const data = JSON.stringify(e.data);
  if (Object.keys(data).length !== 0) {
    localStorage.setItem("webMsg", data);
  }
  // if (data.notifyLevel === "Minor") {
  //   ElementUI.Notification({
  //     title: "",
  //     message: "请求成功!",
  //     type: "success",
  //     duration: 3000,
  //   });
  // } else if (data.notifyLevel === "Warning") {
  //   // ElementUI.Notification({
  //   //   title: "",
  //   //   message: "电表异常",
  //   //   type: "error",
  //   //   duration: 0,
  //   // });
  // }
}
// 关闭websiocket
function closeWebsocket() {
  console.log("连接已关闭...");
}
function close() {
  this.socket.close(); // 关闭 websocket
  this.socket.onclose = function (e) {
    console.log(e); //监听关闭事件
    console.log("关闭");
  };
}
function webSocketSend(agentData) {
  console.log(agentData);
  this.socket.send(agentData);
}
export default {
  initWebSocket,
  close,
};
