import Vue from "vue";
import Vuex from "vuex";
import user from "./user";
import project from "./project";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";
import createPersistedstate from "vuex-persistedstate";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userInfo: {}, //用户数据
    userImage: "",
    isLogin: false, //登录状态
    token: "", //用户登录成功后持有的token
    currentPathName: "",
  },
  //关联着其他三个目录，目的松耦合
  getters,
  mutations,
  actions,
  modules: {
    user,
    project,
  },
  plugins: [
    createPersistedstate({
      reducer(val) {
        return {
          userImage: val.userImage,
        };
      },
    }),
  ],
});
export default store;
