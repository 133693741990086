import axios from "axios";
import { Message, Loading } from "element-ui";
import { saveAs } from "file-saver";

const service = axios.create({
  baseURL: "",
  timeout: 50 * 1000,
});
let downloadLoadingInstance;
service.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    config.headers = {
      // 'Content-Type': 'application/x-www-form-urlencoded' //配置请求头
      "Content-Type": "application/json; charset=utf-8", //配置请求头
      "token":sessionStorage.getItem("userToken")
    };
    // config.headers.Authorization = sessionStorage.getItem("userToken");
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      Message.error("token失效,请重新登陆!");
      this.$router.push("/");
    }
    if (error.response && error.response.status === 500) {
      Message.error("服务异常，请联系管理员！");
      return false;
    }
    return Promise.reject(error);
  }
);
/**
 * 参数处理
 * @param {*} params  参数
 */
export function tansParams(params) {
  let result = "";
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && typeof value !== "undefined") {
      if (typeof value === "object") {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && typeof value[key] !== "undefined") {
            let params = propName + "[" + key + "]";
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result;
}
// 验证是否为blob格式
export async function blobValidate(data) {
  try {
    const text = await data.text();
    JSON.parse(text);
    return false;
  } catch (error) {
    return true;
  }
}
// 通用下载方法
export function download(url, params, filename) {
  downloadLoadingInstance = Loading.service({
    text: "正在下载数据，请稍候",
    spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  let resIds = params;
  return axios
    .post(url, resIds, {
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "token": sessionStorage.getItem("userToken"),
      },
      responseType: "blob",
    })
    .then(async (data) => {
      console.log(data)
      const isLogin = await blobValidate(data);
      if (isLogin) {
        const blob = new Blob([data]);
        saveAs(blob, filename);
      } else {
        const resText = await data.text();
        const rspObj = JSON.parse(resText);
        const errMsg =
          errorCode[rspObj.code] || rspObj.msg || errorCode["default"];
        Message.error(errMsg);
      }
      downloadLoadingInstance.close();
    })
    .catch((r) => {
      console.error(r);
      Message.error("下载文件出现错误，请联系管理员！");
      downloadLoadingInstance.close();
    });
}
//4.导入文件
export default service;
