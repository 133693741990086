//更改用户状态信息
export const userStatus = (state, user) => {
  //判断用户是否存在
  if (user != null) {
    state.userInfo = JSON.parse(user);
    state.isLogin = true;
  } else if (user == null) {
    //登出时清空sessionStroage里面的参数
    sessionStorage.setItem("user", null);
    sessionStorage.setItem("userToken", "");
    state.isLogin = false;
  }
};
export const setPath = (state) => {
  state.currentPathName = localStorage.getItem("currentPathName");
};
export const setUserImage = (state, image) => {
  state.userImage = image;
};
//更改token
export const setToken = (state, token) => {
  if (token != null) {
    state.token = token;
  } else {
    state.token = "";
  }
};
